<template>
<div>
    <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 5 : 5"
        :dark="dark"
        :clickable-rows="true"
        :sorter="{ external: true, resetable: true }"
        pagination
    >


        <template #is_active="{ item }">
            <td>
                <CSwitch
                    class="mt-1 ml-2 mr-2"
                    color="primary"
                    :id="item.id"
                    :checked="item.is_active"
                    @update:checked="activeUser(item)"
                    shape="pill"
                />
            </td>
        </template>

        <template #Action="{ item }">
            <td class="d-flex">
                
                <CButton
                    color="secondary"
                    size="sm"
                    class="mr-2 mb-2"
                    :id="item.id"
                    @click="editUser(item)"
                    ><CIcon name="cil-pencil"
                /></CButton>
                <CButton
                    color="danger"
                    size="sm"
                    class="mr-2 mb-2"
                    :id="item.id"
                    @click="openModal(item)"
                    ><CIcon name="cil-trash"
                /></CButton>
            </td>
        </template>
    </CDataTable>

    <CModal
        title="Delete admin user"
        :show.sync="myModal"
        size="sm"
        >
        Are you sure you want to proceed?
        <template #footer>
            <CButton @click="myModal= false" color="danger">Cancel</CButton>
            <CButton @click="deleteUser()" color="success">Delete</CButton>
      </template>
    </CModal>

    
</div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const updateUserUrl = URL + `${"profile/user/edit/"}`;

export default {
    name: "AdminTable",
    data () {
        return{
            myModal: false,
            deleteElement: null,
            checkError: true,
        }
    },
    methods: {
        activeUser(item) {
            this.axios
                .put(
                    updateUserUrl,
                    { is_active: !item.is_active },
                    {
                        headers: {
                            Authorization: `token ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                        params: { id: item.id },
                    }
                )
                .then((response) => {
                    // this.$router.push("/admin");
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },
        editUser(item) {
            this.$router.push({ name: "Edit account", params: { item } });
        },

        deleteUser() {
            this.axios
                .delete(updateUserUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.deleteElement.id },
                })
                .then((response) => {
                    this.deleteElement= null;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });  
            
        },

        openModal(item){
            this.myModal= true;
            this.deleteElement= item;
        }
    },

    props: {
        items: Array,
        fields: {
            type: Array,
            default() {
                return {
                    items: Array,

                    fields: {
                        type: Array,
                    }
                }; 
            },
        },
        caption: {
            type: String,
            default: "AdminTable",
        },
        hover: Boolean,
        striped: Boolean,
        border: Boolean,
        small: Boolean,
        fixed: Boolean,
        dark: Boolean,
    },
};
</script>
